import { ErrorBoundary } from "components/errorBoundary";
import { AuthProvider } from "providers/auth";
import { LayoutProvider } from "providers/layout";
import { MasterDataProvider } from "providers/master-data";
import { NotifyProvider } from "providers/notify";
import React from "react";

export const ProviderApp: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ErrorBoundary>
      <NotifyProvider>
        <LayoutProvider>
          <AuthProvider>
            <MasterDataProvider>{children}</MasterDataProvider>
          </AuthProvider>
        </LayoutProvider>
      </NotifyProvider>
    </ErrorBoundary>
  );
};
