import { EditOutlined } from "@ant-design/icons";
import AVATAR_DEFAULT from "assets/images/avatar_default.jpeg";
import { IMAGE_TYPE_ACCEPT } from "constants/schema";
import { useAsync } from "hooks/useAsync";
import { useNotify } from "providers/notify";
import React, { useId } from "react";
import { apiUtilsUploadSingle } from "services/utils";
import { Image } from "../image";
interface UploadSingleProps {
  value?: string;
  onChange?: (value: string) => void;
  readonly?: boolean;
}

export const Avatar: React.FC<UploadSingleProps> = ({
  value,
  onChange,
  readonly,
}) => {
  const { api } = useNotify();
  const [executeUpload] = useAsync(apiUtilsUploadSingle, {
    onSuccess: (res) => {
      if (onChange) {
        onChange(res.data.data?.result?.[0]?.images?.[0] || "");
      }
    },
    onFailed() {
      api!.error({
        message: `Thông báo`,
        description: `Upload file lỗi`,
      });
    },
  });

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) return;
    const file = e.target?.files[0];
    if (!IMAGE_TYPE_ACCEPT.includes(file.type)) return;
    executeUpload(file);
    e.target.value = "";
  };

  const id = useId();

  return (
    <>
      <label
        htmlFor={id}
        style={{
          margin: "0 auto",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: "150px",
          height: "150px",
          pointerEvents: readonly ? "none" : undefined,
        }}
      >
        {/* {!value && <UploadOutlined style={{ fontSize: 30 }} />} */}
        <div
          style={{
            borderRadius: "50%",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <Image
            ratio="ratio1x1"
            src={
              !value
                ? AVATAR_DEFAULT
                : typeof value === "string"
                ? value
                : URL.createObjectURL(value as any)
            }
          />
        </div>
        <EditOutlined
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            color: "blue",
            fontSize: "30px",
          }}
        />
        <input
          style={{ display: "none" }}
          onChange={handleChange}
          id={id}
          type="file"
          accept="image/png, image/jpeg"
        />
      </label>
    </>
  );
};
