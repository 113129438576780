import React from "react";

import { PATHS } from "constants/routes";
import { authStorage } from "helpers/localStorage";
import { useAsync } from "hooks/useAsync";
import { AuthAction, useAuth } from "providers/auth";
import { MasterDataAction, useMasterData } from "providers/master-data";
import { useNotify } from "providers/notify";
import { Navigate, useNavigate } from "react-router-dom";
import { apiAdminGetInfo, apiGetDistrict, apiGetMasterData } from "services/admin";

export const withPrivate =
  (WrappedComponent: React.FC<any>) => (props: any) => {
    const navigate = useNavigate();
    const token = authStorage.get("token");
    const {
      dispatch,
      state: { user },
    } = useAuth();

    const { dispatch: dispatchMasterData } = useMasterData()
    const { api } = useNotify();

    useAsync(apiAdminGetInfo, {
      onSuccess: (res) => {
        dispatch({
          type: AuthAction.setAuth,
          payload: res.data.data.result[0],
        });
      },
      onFailed: () => {
        navigate(PATHS.login, { replace: true });
      },
      callOnFirst: !user && token,
    });

    useAsync(apiGetMasterData, {
      onSuccess: (res) => {
        dispatchMasterData({
          type: MasterDataAction.setMasterData,
          payload: res.data.data.result[0],
        });
      },
      onFailed: () => {
        api!.error({
          message: `Thông báo`,
          description: `Request master data error`,
        });
      },
      callOnFirst: true,
    });

    useAsync(apiGetDistrict, {
      onSuccess: (res) => {
        dispatchMasterData({
          type: MasterDataAction.setDistrict,
          payload: { districts: res.data.data.result},
        });
      },
      onFailed: () => {
        api!.error({
          message: `Thông báo`,
          description: `Request master data error`,
        });
      },
      callOnFirst: true,
    });

    if (!token && !user) {
      return <Navigate to={PATHS.login} replace />;
    }

    if (user) {
      return <WrappedComponent {...props} />;
    }

    return <div />;
  };
